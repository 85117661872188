<template>
  <div class="menuBar">
    <div
      v-for="menu in menuList"
      :key="menu.key"
      @click="clickMenu(menu.route)"
      :class="{ active: selectedMenu == menu.route }"
    >
      {{ menu.name }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedMenu: "ManageProductAll",
      menuList: [],
    };
  },
  methods: {
    getNameConfirmMenu() {
      if (this.$isSeller) {
        return "ที่ต้องยืนยัน";
      }

      if (this.$isBuyer) {
        return "รอยืนยัน";
      }
    },
    clickMenu(val) {
      this.selectedMenu = val;
      this.$emit("selectMenu", val);
      if (this.$route.name != this.selectedMenu) {
        this.$router.push({ name: val });
      }
    },
  },
  beforeMount() {
    this.menuList = [
      {
        name: this.getNameConfirmMenu(),
        key: "confirm",
        route: "OrderConfirm",
      },
      {
        name: "กำลังดำเนินการ",
        key: "progress",
        route: "OrderProgress",
      },
      {
        name: "ประวัติ",
        key: "history",
        route: "OrderHistory",
      },
    ];
  },
  mounted() {
    this.selectedMenu = this.$route.name;
  },
};
</script>
<style lang="scss" scoped>
.menuBar {
  user-select: none;
  display: flex;
  background: #ffffff;
  justify-content: center;
  // align-items: center;
  width: 100%;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.2);
  > div {
    width: 100%;
    text-align: center;
    color: #55585b; //rgb(131, 131, 131);
    font-size: clamp(12px, 3.5vw, 13px);
    padding: 3px 2px 10px 2px;
    display: inline-block;
    border-radius: 8px;
    margin: 0 4px;
    cursor: pointer;
    // height: 30px;
    &.active {
      position: relative;
      // color: #ffffff; // rgb(112, 112, 112);
      // border-color: rgb(218, 218, 218);
      // background: var(--primary-color); //rgb(218, 218, 218);
    }
  }
}

.active:after {
  content: "";
  width: 80%;
  border-bottom: 2px solid var(--primary-color) !important;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
}
</style>
