<template>
  <div>
    <div class="productDetail">
      <div class="wrapImg">
        <img
          v-if="
            item.product.productPhotos && item.product.productPhotos.length > 0
          "
          :src="item.product.productPhotos[0].url"
          width="50px"
        />
      </div>
      <span>
        {{ item.premium ? "[ของแถม]" : "" }} {{ item.product.name }}
        <div v-if="!item.premium" class="priceWrap">
          <span class="price">
            <span v-if="calStep(item.product)"
              >{{ calStep(item.product).min.toLocaleString() }} ~
              {{ calStep(item.product).max.toLocaleString() }}</span
            >
            <span v-else>{{ item.product.salePrice.toLocaleString() }}</span>
          </span>
          <span class="unitPrice">บาท</span>
          <span class="fromPrice"
            >{{ item.product.price.toLocaleString() }}
            <span class="unitPrice">บาท</span></span
          >
        </div>
        <div class="amout">
          จำนวน <span class="valueUnit">{{ item.qty.toLocaleString() }}</span>
          {{ item.product.unit || "" }}
        </div>
      </span>
    </div>
    <table v-if="showDelivery" class="orderListStatus">
      <div>
        <p v-if="$isSeller">ค้างส่ง</p>
        <p v-if="$isBuyer">รอส่ง</p>
        <div :class="[{ danger: $isSeller, warning: $isBuyer }]">
          {{ item.qty - item.ship }}
        </div>
      </div>
      <div>
        <p>ส่งแล้ว</p>
        <div class="warning">{{ item.ship }}</div>
      </div>
      <div>
        <p v-if="$isSeller">รอรับ</p>
        <p v-if="$isBuyer">ที่ต้องรับ</p>
        <div :class="[{ danger: $isBuyer, warning: $isSeller }]">
          {{ item.ship - item.receive }}
        </div>
      </div>
      <div>
        <p>ส่งสำเร็จ</p>
        <div class="success">{{ item.receive }}</div>
      </div>
    </table>
  </div>
</template>

<script>
export default {
  props: { item: Object, showDelivery: Boolean },
};
</script>

<style lang="scss" scoped>
.productDetail {
  display: flex;

  .wrapImg {
    margin-right: 7px;
  }

  .priceWrap {
    .fromPrice {
      font-size: 12px;
      margin-left: 6px;
      color: #a5a5a5;
      text-decoration: line-through;
    }

    .price {
      font-size: 13px;
      padding-right: 4px;
      color: var(--primary-color);
    }
  }
  .valueUnit {
    color: var(--primary-color);
    font-weight: bold;
  }
}

.orderListStatus {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin: 10px auto;
  > div {
    position: relative;
    width: 100%;
    > p {
      padding: 3px 0;
    }
    > div {
      padding: 3px 0;
      font-weight: bold;
    }
    &:after {
      content: "";
      height: 80%; //You can change this if you want smaller/bigger borders
      width: 1px;
      position: absolute;
      right: 0;
      top: 50%; // If you want to set a smaller height and center it, change this value
      transform: translate(0, -50%);
      background-color: #eeeeee; // The color of your border
    }
    &:last-child:after {
      width: 0;
    }
  }
}

.success {
  color: #57b66a;
}

.warning {
  color: #ff9900;
}

.danger {
  color: #cc0000;
}
</style>