export const statusKey = {
    payment_pending: {
        buyer: { s: "danger", n: "ที่ต้องชำระ" },
        seller: { s: "warning", n: "รอชำระ" },
    },
    payment_inform: {
        buyer: { s: "warning", n: "รอตรวจสอบ" },
        seller: { s: "danger", n: "ที่ต้องตรวจสอบ" },
    },
    payment_paid: {
        buyer: { s: "pass", n: "ชำระแล้ว" },
        seller: { s: "pass", n: "ชำระแล้ว" },
    },
    ship_ship: {
        buyer: { s: "danger", n: "ที่ต้องได้รับ" },
        seller: { s: "warning", n: "รอยืนยันรับ" },
    },
    ship_pending: {
        buyer: { s: "warning", n: "รอส่งสินค้า" },
        seller: { s: "danger", n: "ที่ต้องส่ง" },
    },
    ship_deliver: {
        buyer: { s: "pass", n: "ส่งสำเร็จ" },
        seller: { s: "pass", n: "ส่งสำเร็จ" },
    },
    complete: {
        buyer: { s: "pass", n: "เสร็จสิ้น" },
        seller: { s: "pass", n: "เสร็จสิ้น" },
    },
    cancel: {
        buyer: { s: "cancel", n: "ยกเลิก" },
        seller: { s: "cancel", n: "ยกเลิก" },
    }
}

const mapStatus = {
    pending: {
        pending: {
            pending: [{
                buyer: { s: "warning", n: "รอยืนยัน" },
                seller: { s: "danger", n: "ที่ต้องยืนยัน" },
            }],
        },
    },
    inprogress: {
        pending: {
            pending: [statusKey.payment_pending
            ],
            ship: [
                statusKey.payment_pending,
                statusKey.ship_ship
            ],
            deliver: [
                statusKey.payment_pending,
                statusKey.ship_deliver
            ]
        },
        inform: {
            pending: [statusKey.payment_inform],
            ship: [
                statusKey.payment_inform,
                statusKey.ship_ship
            ],
            deliver: [
                statusKey.payment_inform,
                statusKey.ship_deliver
            ]
        },
        paid: {
            pending: [
                statusKey.payment_paid,
                statusKey.ship_pending
            ],
            ship: [
                statusKey.payment_paid,
                statusKey.ship_ship
            ],
            deliver: [
                statusKey.ship_deliver
            ],
        },
    },
    cancel: [statusKey.cancel],
    complete: {
        paid: {
            deliver: [statusKey.complete],
        },
    }
}

function getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj) || [];
}

export default function getStatus(order) {
    if (order.status === 'cancel') {
        return mapStatus.cancel
    }

    let result = getNested(
        mapStatus,
        order.status,
        order.paymentStatus,
        order.shipStatus
    );

    if (order.paymentType.name === 'เครดิต' && order.status === 'inprogress' && (order.paymentStatus === 'pending' || order.paymentStatus === 'inform') && order.shipStatus === 'pending') {
        result = [...result, statusKey.ship_pending]
    }

    return result
}