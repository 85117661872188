import Compressor from "compressorjs";

export default (file) => {
    return new Promise((resolve) => {
        new Compressor(file, {
            quality: 0.8,
            convertSize: 0,
            maxWidth: 800,
            maxHeight: 800,
            success: async (resultFile) => {
                console.log(resultFile)
                resolve({
                    resultFile,
                    fileName: file.name,
                });
            },
            error: (err) => {
                console.error(err.message);
                resolve({});
            },
        });
    })
}