<template>
  <div class="orderHistory h100">
    <OrderList :filters="filters" :filtersKey="filtersKey" />
  </div>
</template>
<script>
import OrderList from "./OrderList";

export default {
  components: { OrderList },
  data() {
    return {
      filters: {
        orderStatus: ["done"],
      },
      filtersKey: ["complete", "cancel"],
    };
  },
};
</script>