<template>
  <div class="orderDetail" v-if="order.id">
    <NavbarBack
      title="รายละเอียดออเดอร์"
      :defaultPreRouteName="defaultPreRouteName"
    />
    <div class="orderDataSection">
      <div class="head">
        <div class="title">
          <OrderIcon />
          ข้อมูลออเดอร์
        </div>
      </div>
      <div class="orderDataBox">
        <div class="sideFlex">
          <div class="left">
            <div class="group">
              <b>เลขที่:</b>
              <div>{{ order.orderNumber }}</div>
            </div>
            <div class="group">
              <b>ร้านค้า:</b>
              <div :class="[order.seller.shopType, 'shopName']">
                {{ order.seller.name }}
              </div>
            </div>
            <div class="group">
              <b>ประเภทร้านค้า:</b>
              <div>{{ getTextKey(order.seller.shopType) }}</div>
            </div>
            <div class="group">
              <b>การชำระเงิน:</b>
              <div>{{ order.paymentType.name }}</div>
            </div>
          </div>
          <div class="right">
            <div class="group">
              <b>วันที่สั่งซื้อ:</b>
              <div>{{ $formatDate(order.createdAt) }}</div>
            </div>
            <div class="group">
              <b>ลูกค้า:</b>
              <div :class="[order.buyer.shopType, 'shopName']">
                {{ order.buyer.name }}
              </div>
            </div>
            <div class="group">
              <b>ประเภทลูกค้า:</b>
              <div>{{ getTextKey(order.buyer.shopType) }}</div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="bottomSection">
          <div class="tags" v-if="mapOrderStatus(order).length > 0">
            <div
              :class="status[$shopType].s"
              v-for="(status, index) of mapOrderStatus(order)"
              :key="index"
            >
              {{ status[$shopType].n }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <OrderListDetailSection :orderlist="orderlist" :order="order" />
    <OrderPaymentDetailSection :payments="payments" />

    <div class="addressSection">
      <div class="head">
        <div class="title">
          <!-- <img src="@/assets/img/location.svg" width="20px" alt="" /> -->
          <div class="mask-image"></div>
          ที่อยู่จัดส่ง
        </div>
      </div>
      <div class="box">
        <!-- {{ order.shipAddress.name }}<br /> -->
        {{ order.shipAddress.contact_person }}, {{ order.shipAddress.tel
        }}<br />
        {{ order.shipAddress.detail }},
        {{ order.shipAddress.subDistrict.province }},
        {{ order.shipAddress.subDistrict.district }},
        <!-- {{ order.shipAddress.subDistrict.name }}, -->
        {{ order.shipAddress.postcode }}
        <!-- <div style="height: 100px"></div> -->
      </div>
    </div>

    <OrderDeliveryPhotoSection :order="order" />

    <div class="historySection">
      <div class="head">
        <div class="title">
          <!-- <img src="@/assets/img/history_icon.svg" width="20px" alt="" /> -->
          <div class="mask-image"></div>
          ประวัติออเดอร์
        </div>
      </div>
      <div class="box">
        <OrderTimeline :data="history" />
      </div>
    </div>

    <div class="actionSection">
      <div
        v-if="
          $isBuyer &&
          order.paymentStatus === 'pending' &&
          order.status === 'inprogress'
        "
        class="comfirmBtn"
        @click="confirmPayment"
      >
        แจ้งชำระเงิน
      </div>
      <div
        v-if="$isSeller && order.paymentStatus === 'inform'"
        class="approveInformBtn btn"
        @click="handleFetch(approveInform())"
      >
        ยืนยันตรวจสอบข้อมูลชำระเงิน
      </div>
      <div
        v-if="$isSeller && order.status === 'pending'"
        class="comfirmBtn"
        @click="confirmAlert = true"
      >
        ยืนยันออเดอร์
      </div>
      <div
        v-if="
          $isSeller &&
          order.status === 'inprogress' &&
          order.shipStatus === 'pending' &&
          (order.paymentStatus === 'paid' ||
            order.paymentType.name === 'เครดิต')
        "
        class="comfirmBtn"
        @click="
          $router.push(
            `/order/${orderId}/ship-update`
          ) /*handleFetch(confirmShip())*/
        "
      >
        ยืนยันส่งสินค้า
      </div>
      <div
        v-if="$isBuyer && haveToReceive"
        class="comfirmBtn"
        @click="
          $router.push(
            `/order/${orderId}/receive-update`
          ) /*handleFetch(confirmReceive())*/
        "
      >
        ยืนยันได้รับสินค้า
      </div>
      <div
        @click="cancelAlert = true"
        class="cancelBtn"
        v-if="order.status != 'complete' && order.status != 'cancel'"
      >
        ยกเลิกออเดอร์
      </div>
    </div>

    <!-- alert confirm -->
    <Modal :open="confirmAlert" :closeIcon="false" class="alertConfirm">
      <div class="alertBody">
        <div class="alertTitle"><b>ยืนยันออเดอร์</b></div>
        <div class="orderNumberSection">
          <b>เลขที่</b> {{ order.orderNumber }}
        </div>
        <div>
          <div class="noteTitle">หมายเหตุ</div>
          <textarea v-model="noteConfirm" id="noteConfirm" rows="4"></textarea>
        </div>
      </div>
      <div class="wrapAction">
        <div id="cancelBtn" class="action" @click="confirmAlert = false">
          ปิด
        </div>
        <div id="okBtn" @click="confirmOrder" class="action">ยืนยัน</div>
      </div>
    </Modal>

    <!-- alert cancel -->
    <Modal :open="cancelAlert" :closeIcon="false" class="alertCancel">
      <div class="alertBody">
        <div class="alertTitle"><b>ยกเลิกออเดอร์</b></div>
        <div class="orderNumberSection">
          <b>เลขที่</b> {{ order.orderNumber }}
        </div>
        <div>
          <div class="noteTitle">หมายเหตุ</div>
          <textarea v-model="noteCancel" id="noteCancel" rows="4"></textarea>
        </div>
      </div>
      <div class="wrapAction">
        <div id="cancelBtn" class="action" @click="cancelAlert = false">
          ปิด
        </div>
        <div id="okBtn" @click="cancelOrder()" class="action">ยืนยัน</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import OrderIcon from "@/components/menuIcon/OrderIcon";
import Modal from "@/components/Modal";
import OrderTimeline from "./components/OrderTimeline";
import OrderListDetailSection from "./components/OrderListDetailSection";
import OrderPaymentDetailSection from "./components/OrderPaymentDetailSection";
import OrderDeliveryPhotoSection from "./components/OrderDeliveryPhotoSection";

import { GET, POST } from "@/utils/http";
import SmartPhoto from "smartphoto";
import "smartphoto/css/smartphoto.min.css";
import mapOrderStatus from "@/utils/mapOrderStatus";
import { calStep, calUnitPrice } from "@/utils/product";
import getTextKey from "@/utils/getTextKey";

export default {
  props: { orderId: String },
  components: {
    NavbarBack,
    OrderIcon,
    Modal,
    OrderTimeline,
    OrderListDetailSection,
    OrderPaymentDetailSection,
    OrderDeliveryPhotoSection,
  },
  data() {
    return {
      confirmAlert: false,
      cancelAlert: false,
      noteConfirm: "",
      noteCancel: "",
      orderlist: [],
      history: [],
      order: {},
      loading: false,
      payments: [],
      smartPhoto: null,
    };
  },
  computed: {
    defaultPreRouteName() {
      return {
        pending: "OrderConfirm",
        inprogress: "OrderProgress",
        cancel: "OrderHistory",
        complete: "OrderHistory",
      }[this.order.status];
    },
    address() {
      return this.order.shipAddress || {};
    },
    haveToReceive() {
      return this.orderlist.some((e) => e.ship - e.receive > 0);
    },
    haveToShip() {
      return this.orderlist.some((e) => e.qty - e.ship > 0);
    },
  },
  watch: {
    loading(v) {
      console.log(v);
    },
  },
  methods: {
    calUnitPrice: calUnitPrice,
    calStep: calStep,
    mapOrderStatus: mapOrderStatus,
    getTextKey: getTextKey,
    async getOrder() {
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}?photo=true`
      );
      if (status !== 200) {
        throw "order index: not ok is status " + status;
      }
      this.order = body;
    },
    async getOrderlists() {
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/orderlists?query=&order=unitPrice:desc`
      );
      if (status !== 200) {
        throw "order: not ok";
      }
      this.orderlist = body;
    },
    async getPaymentsOrder() {
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/${this.$shopType}/payments?query=&page=1&order=approve:desc?query=&order=unitPrice:desc`
      );
      if (status !== 200) {
        throw "order payments: not ok";
      }
      this.payments = body.data;
    },
    async getOrderHistory() {
      const { status, body } = await GET(
        `/api/orders/${this.orderId}/histories?orderId=${this.orderId}&order=createdAt:desc`
      );
      if (status !== 200) {
        throw "order: not ok";
      }
      console.log(body);
      this.history = body;
    },
    confirmPayment() {
      this.$router.push(
        `/informPayment/${this.order.seller.id}/${this.order.id}/${this.order.net}`
      );
    },
    async confirmOrder() {
      try {
        window.loading(true);
        const reqBody = {
          approve: true,
          note: this.noteConfirm,
        };
        const { status } = await POST(
          `/api/shops/${this.$myShop.id}/orders/${this.orderId}/approve`,
          reqBody
        );

        if (status !== 200) {
          throw "order confirm: not ok is status " + status;
        }

        window.loading(false);
        await window.$alert("ยืนยันออเดอร์แล้ว");
        this.$router.push("/order/progress");
      } catch (err) {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      } finally {
        window.loading(false);
      }
    },
    async cancelOrder() {
      try {
        window.loading(true);
        const reqBody = {
          note: this.noteCancel,
        };
        const { status } = await POST(
          `/api/shops/${this.$myShop.id}/orders/${this.orderId}/${this.$shopType}/cancel`,
          reqBody
        );

        if (status !== 200) {
          throw "order cancel: not ok is status " + status;
        }

        await window.$alert("ยกเลิกออเดอร์แล้ว");
        this.$router.push("/order/history");
      } catch (err) {
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      } finally {
        window.loading(false);
      }
    },
    async approveInform() {
      if (!(await window.$alert("ยืนยันการตรวจสอบ?", "confirm"))) {
        return;
      }

      window.loading(true);
      const { status } = await POST(
        `/api/shops/${this.$myShop.id}/payments/${this.payments[0].id}/approve`
      );
      window.loading(false);

      if (status !== 200) {
        throw "approve inform: not ok is status " + status;
      }
      await window.$alert("ตรวจสอบข้อมูลชำระเงินแล้ว");
      this.init();
    },
    async init() {
      if (this.smartPhoto) {
        this.smartPhoto.destroy();
      }
      window.loading(true);
      try {
        await Promise.all([
          this.getOrderlists(),
          this.getOrder(),
          this.getPaymentsOrder(),
          this.getOrderHistory(),
        ]);

        // init viewer
        this.$nextTick(() => {
          if (document.querySelectorAll(".js-smartphoto").length > 0) {
            this.smartPhoto = new SmartPhoto(".js-smartphoto", {
              arrows: false,
              nav: false,
              useOrientationApi: false,
            });
          }
        });
      } catch (err) {
        await window.$alert("พบข้อผิดพลาดการขอข้อมูล");
        this.$router.back();
      } finally {
        window.loading(false);
        // this.loading = false;
      }
    },
    async confirmShip() {
      if (!(await window.$alert("ยืนยันส่งสินค้าแล้ว?", "confirm"))) {
        return;
      }
      window.loading(true);
      const { status } = await POST(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/delivery/ship/all`
      );
      window.loading(false);

      if (status !== 200) {
        throw "confirm deliver: not ok is status " + status;
      }
      await window.$alert("ยืนยันการส่งสินค้าแล้ว");
      this.init();
    },
    async confirmReceive() {
      if (!(await window.$alert("ยืนยันได้รับสินค้าแล้ว?", "confirm"))) {
        return;
      }
      window.loading(true);
      const { status } = await POST(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/delivery/receive/all`
      );
      window.loading(false);

      if (status !== 200) {
        throw "confirm receive all: not ok is status " + status;
      }
      await window.$alert("ยืนยันได้รับสินค้าแล้ว");
      this.init();
    },
    async handleFetch(fn) {
      try {
        await fn;
      } catch (err) {
        console.error(err);
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      } finally {
        window.loading(false);
      }
    },
  },
  async mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "./styles/tags.scss";
@import "./styles/orderDetail.scss";

.shopName {
  &.sub-agent {
    color: #93c47d;
  }
  &.sub-direct {
    color: #93c47d;
  }
  &.agent {
    color: #38761d;
  }
  &.retail {
    color: #4a86e8;
  }
}

.actionSection {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2px 10px;
  background: rgba(255, 255, 255, 0.63);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  .comfirmBtn,
  .cancelBtn {
    text-align: center;
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    padding: 15px;
    font-weight: bold;
    display: block;
    margin: 10px auto;
  }

  .comfirmBtn {
    background: var(--primary-color);
    color: #fff;
  }

  .cancelBtn {
    background: #fff;
    color: rgb(117, 117, 117);
    border: 2px solid rgb(218, 218, 218);
  }
}

.addressSection {
  .mask-image {
    mask-image: url("~@/assets/img/location.svg");
  }
}

.historySection {
  .mask-image {
    mask-image: url("~@/assets/img/history_icon.svg");
  }
}

.approveInformBtn {
  background: var(--warning-color);
  color: #fff;
}

.orderDetail {
  padding: 40px 0 200px 0;
}

.line {
  margin: 0 auto;
  padding: 3px 0;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.bottomSection {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
}

.orderDataBox {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ddd;
  /* height: 280px; */
  span {
    font-weight: bold;
  }

  .sideFlex {
    display: flex;
    justify-content: space-between;
    .left {
      padding-right: 5px;
    }
  }

  .group {
    b {
      color: rgb(70, 70, 70);
      font-size: 13px;
    }
    margin: 5px 0;
  }

  .wrapFirst,
  .actionBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    .editBtn {
      color: rgb(148, 148, 148);
      font-weight: bold;
      border: 2px solid rgb(218, 218, 218);
      padding: 4px 10px;
      border-radius: 5px;
    }
  }
}

/* The Modal (background) */
.alertConfirm,
.alertCancel {
  .alertBody {
    color: rgb(122, 122, 122);
    margin: 0 0 5px 0;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-size: unset !important;
    min-width: max-content;
    width: 65vw;
    max-width: 400px;
  }
  .alertTitle {
    font-size: 17px;
    text-align: center;
  }

  .commentTitle {
    line-height: 25px;
    text-align: left;
    font-size: 14px;
  }

  #noteConfirm,
  #noteCancel {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
  }

  .orderNumberSection {
    font-size: 14px;
    text-align: center;
  }
}

.alertConfirm {
  #okBtn {
    background: var(--primary-color);
    color: #fff;
  }

  #cancelBtn {
    background: #fff;
    color: rgb(117, 117, 117);
    border: 2px solid rgb(218, 218, 218);
  }

  .wrapAction {
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }
  .action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    &:active {
      background: rgba(226, 226, 226, 0.507);
    }
  }
}

.alertCancel {
  #okBtn {
    background: var(--primary-color) !important;
    color: #ffffff;
  }

  #cancelBtn {
    background: #fff;
    color: rgb(117, 117, 117);
    border: 2px solid rgb(218, 218, 218);
  }

  .wrapAction {
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }
  .action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    &:active {
      background: rgba(226, 226, 226, 0.507);
    }
  }
}
</style>
