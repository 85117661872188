<template>
  <div class="orderListLoader" v-if="error || nodata || fetching">
    <div v-if="fetching" class="cardWrap">
      <div class="card" v-for="index in 4" :key="index">
        <div class="skeleton" aria-busy="true">
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <rect
                width="100%"
                height="100%"
                rx="2"
                fill="white"
                fill-opacity="1"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fetching: Boolean,
    error: Boolean,
    nodata: Boolean,
  },
};
</script>

<style lang="scss" scoped>
// @import "~@/styles/productCardList.scss";
@import "~@/styles/skeleton.scss";

.orderListLoader {
  height: 100%;
  width: 100%;
}

.card {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  height: 240px;
  border: none;
  margin: 15px 10px 15px 10px;
  max-width: 450px;
}
</style>