<template>
  <div>
    <div class="contents">
      <div class="wrapImgList" v-show="selectedImgs">
        <div
          v-for="(item, index) in selectedImgs"
          :key="index"
          @click="imgClick(index)"
        >
          <img
            class="boxUploadImage previewImg"
            width="100%"
            :src="item.photo"
          />
        </div>
        <label
          v-if="canUpload"
          :for="'uploadInput_' + name"
          class="boxUploadImage"
        >
          <div>
            <img width="17" src="@/assets/img/add.svg" />
            <div>เพิ่มรูปภาพ</div>
          </div>
        </label>
      </div>
      <input
        type="file"
        ref="fileInput"
        :capture="getMobileOperatingSystem() == 'iOS' ? false : true"
        accept="image/*"
        @change="fileChange"
        class="fileInput"
        :id="'uploadInput_' + name"
      />
      <div class="statusMessage" v-if="!canUpload && selectedImgs.length === 0">
        ยังไม่มีข้อมูล
      </div>
      <!-- <div v-if="!noFile" class="remove" @click="reset()">ลบไฟล์ที่เลือก</div> -->
    </div>
    <!-- <Modal :open="cropModal" :closeIcon="false">
      <div class="modalBody">
        <div id="croppie"></div>
        <div class="btn cropBtn" @click="clickCrop">ตกลง</div>
      </div>
    </Modal> -->
    <Modal :open="showImg !== null" :closeIcon="false" class="showImgModal">
      <div class="showImgModalBody" v-if="showImg">
        <div class="wrapShowImg">
          <img
            :src="showImg.photo"
            :onerror="`this.src='${$placeholderImg}';this.onerror = null`"
            width="100%"
          />
        </div>
        <div class="wrapAction">
          <div class="btn cropBtn" @click="showImg = null">ปิด</div>
          <div
            v-if="canUpload"
            class="btn removeBtn"
            @click="remove(showImg.index)"
          >
            ลบรูปนี้
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
// import Croppie from "croppie/croppie";
// import "croppie/croppie.css";
import Modal from "@/components/Modal";
import { DELETE, POST } from "@/utils/http";
import ImageCompressor from "@/utils/imageCompressor";

export default {
  props: {
    imageUrl: String,
    name: String,
    uploadType: String,
    orderId: Number,
    canUpload: Boolean,
  },
  components: { Modal },
  data() {
    return {
      previewImg: this.imageUrl || null,
      uploading: false, // loading
      noFile: true,
      result: {},
      selectedImgs: [],
      cropModal: false,
      stateImageFileName: null,
      showImg: null,
      oldImgsDelete: [],
    };
  },
  watch: {
    imageUrl(val) {
      this.previewImg = val;
    },
  },
  methods: {
    reset() {
      this.previewImg = null;
      this.uploading = false;
      this.$refs.fileInput.value = null;
      this.noFile = true;
      this.$emit("result", {});
      this.previewImg = this.imageUrl;
    },
    async fileChange(e) {
      const file = e.target.files[0];
      this.noFile = !file;

      if (!file) {
        return;
      }

      if (file.size > 8388608) {
        this.Swal.fire("", "ขนาดไฟล์ไม่ควรเกิน 8MB", "warning");
        return;
      }

      // this.cropModal = true;

      window.loading(true);

      const { resultFile, fileName } = await ImageCompressor(file);
      if (!resultFile || !fileName) {
        window.loading(false);
        await window.$alert("ไม่สามารถลบรูปได้ในขณะนี้ code:001");
        return;
      }

      console.log({ resultFile, fileName });
      this.selectedImgs.push({
        photo: await this.getDataURL(resultFile),
        result: { file: resultFile, fileName: fileName },
      });
      await this.uploadImage(resultFile, fileName);
      window.loading(false);

      // this.stateImageFileName = null;
      //   this.cropDialog(await this.getDataURL(file));
    },
    async getDataURL(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      return new Promise((resolve) => {
        fr.onload = (e) => {
          resolve(e.target.result); // dataURL
        };
      });
    },
    async remove(index) {
      try {
        if (!(await window.$alert("ต้องการลบรูปนี้หรือไม่?", "confirm"))) {
          return;
        }

        this.showImg = null;

        // check delete old img
        if (this.selectedImgs[index].id) {
          window.loading(true);
          await this.deleteImage(this.selectedImgs[index].id);
          window.loading(false);
        }

        this.selectedImgs.splice(index, 1);
      } catch (err) {
        console.error(err);
        window.loading(false);
        await window.$alert("ไม่สามารถลบรูปได้ในขณะนี้ code:002");
      }
    },
    async uploadImage(resultFile, fileName) {
      try {
        window.loading(true);

        const formData = new FormData();

        formData.append("photos", resultFile, fileName);

        formData.append("type", this.uploadType);

        const { status } = await POST(
          `/api/shops/${this.$myShop.id}/orders/${this.orderId}/delivery_photos`,
          formData
        );

        if (status !== 200) {
          throw "post product images: not ok";
        }
      } catch (err) {
        console.error(err);
        window.loading(false);
        await window.$alert("ไม่สามารถเพิ่มรูปได้ในขณะนี้");
        this.$router.back();
      }
    },
    async deleteImage(photoId) {
      const { status } = await DELETE(
        `/api/shops/${this.$myShop.id}/delivery_photos/${photoId}`
      );
      if (status === 204) {
        return true;
      }
      throw "delete img not ok status: " + status;
    },
    async imgClick(imgIndex) {
      this.showImg = {
        photo: this.selectedImgs[imgIndex].photo,
        index: imgIndex,
      };
      // this.remove(index);
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
    // cropDialog(imageUrl) {
    //   this.croppie.bind({ url: imageUrl }).then(() => {
    //     this.croppie.setZoom(0.5);
    //   });
    // },
    // async clickCrop() {
    //   //on button click
    //   await this.croppie
    //     .result({
    //       type: "blob",
    //       backgroundColor: "#ffffff",
    //       size: { width: 600, height: 600 },
    //       format: "jpeg",
    //     })
    //     .then(async (blob) => {
    //       // return blob;

    //       this.selectedImgs.push({
    //         photo: await this.getDataURL(blob),
    //         result: { file: blob, fileName: this.stateImageFileName },
    //       });
    //       this.$emit("result", this.selectedImgs);
    //       this.stateImageFileName = null;
    //       // this.croppie = null;
    //       this.cropModal = false;
    //     });
    // },
  },
  mounted() {
    // var el = document.getElementById("croppie");
    // const bodyWith = document.body.offsetWidth;
    // this.croppie = new Croppie(el, {
    //   viewport: {
    //     width: bodyWith > 400 ? 250 : bodyWith - 100,
    //     height: bodyWith > 400 ? 250 : bodyWith - 100,
    //   },
    //   boundary: {
    //     width: bodyWith > 400 ? 300 : bodyWith - 50,
    //     height: bodyWith > 400 ? 300 : bodyWith - 50,
    //   },
    //   showZoomer: true,
    //   enforceBoundary: false,
    //   // enableOrientation: true,
    // });
  },
};
</script>

<style lang="scss" scoped>
.boxUploadImage {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgb(197, 197, 197);
  text-align: center;
  color: rgb(107, 107, 107);
  font-size: 14px;
  border-radius: 10px;
  background: rgb(231, 231, 231);
  width: 85px;
  height: 85px;
  margin-top: 10px;
  object-fit: cover; /* Do not scale the image */
  object-position: center;
  aspect-ratio: 1/1;
  img {
    margin-bottom: 5px;
  }
}

.wrapImgList {
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 5px;
  }
}

.previewImg {
  border: 1px solid rgb(190, 190, 190);
}

.fileInput {
  opacity: 0;
  position: absolute;
  display: none;
  z-index: -1;
}

.contents {
  display: flex;
  align-items: center;
}

.remove {
  margin-left: 15px;
  display: inline;
  border-radius: 6px;
  background: crimson;
  padding: 4px;
  color: rgb(255, 255, 255);
}

.cropBtn {
  background: #fff;
  border: 1px solid rgb(170, 170, 170);
}

.removeBtn {
  background: crimson;
  border: 1px solid crimson;
  color: #fff;
  width: 50%;
}

.statusMessage {
  margin: 0 auto;
}

.showImgModal {
  .wrapShowImg {
    min-width: 240px;
    width: 100%;
    aspect-ratio: 1/1;
  }
  .wrapAction {
    display: flex;
    margin-top: 10px;
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > div {
      margin: 0 10px;
    }
  }

  .showImgModalBody {
    max-width: 400px;
  }
}
</style>

<style>
.croppie-container .cr-boundary {
  border-radius: 15px;
}

.cr-slider {
  width: 100%;
}

.croppie-container .cr-slider-wrap {
  width: 100%;
}
</style>