<template>
  <div class="h100 orderList">
    <Filters
      v-if="filtersKey.length > 0"
      @metaJoin="updatefilters"
      :list="outputFiltersList"
    />
    <div class="card_wrap">
      <div class="card" v-for="(order, index) in orders" :key="index">
        <div class="sideFlex">
          <div class="left">
            <div class="group">
              <b>เลขที่:</b>
              <div>{{ order.orderNumber }}</div>
            </div>
            <div class="group">
              <b>ร้านค้า:</b>
              <div :class="[order.seller.shopType, 'shopName']">
                {{ order.seller.name }}
              </div>
            </div>
            <div class="group">
              <b>ประเภทร้านค้า:</b>
              <div>{{ getTextKey(order.seller.shopType) }}</div>
            </div>

            <div class="group">
              <b>การชำระเงิน:</b>
              <div>{{ order.paymentType.name }}</div>
            </div>
          </div>
          <div class="right">
            <div class="group">
              <b>วันที่สั่งซื้อ:</b>
              <div>{{ $formatDate(order.createdAt) }}</div>
            </div>
            <div class="group">
              <b>ลูกค้า:</b>
              <div :class="[order.buyer.shopType, 'shopName']">
                {{ order.buyer.name }}
              </div>
            </div>
            <div class="group">
              <b>ประเภทลูกค้า:</b>
              <div>{{ getTextKey(order.buyer.shopType) }}</div>
            </div>
          </div>
        </div>

        <div class="line"></div>
        <div class="sumPriceWrap">
          <span>ราคารวม</span>
          <span
            ><span class="summaryPrice">{{ order.net.toLocaleString() }}</span>
            <span> บาท</span></span
          >
        </div>
        <div class="line"></div>
        <div class="bottomSection">
          <div class="tags" v-if="mapOrderStatus(order).length > 0">
            <div
              :class="status[$shopType].s"
              v-for="(status, index) of mapOrderStatus(order)"
              :key="index"
            >
              {{ status[$shopType].n }}
            </div>
            <!-- <div>
              <div class="wait" v-if="order.status === 'pending'">รอยืนยัน</div>
              <div class="send" v-if="order.status === 'complete'">สำเร็จ</div>
              <div class="cancel" v-if="order.status === 'cancel'">ยกเลิก</div>
            </div>
            <div v-if="order.status === 'pending'">
              <div v-if="order.paymentStatus === 'pending'"></div>
            </div>
            <div v-if="order.status === 'approve'">
              <div class="process" v-if="order.paymentStatus === 'inform'">
                <div v-if="$isSeller">ที่ต้องตรวจสอบ</div>
                <div v-if="$isBuyer">รอตรวจสอบ</div>
              </div>
              <div
                class="wait"
                v-if="
                  order.shipStatus === 'pending' &&
                  order.paymentStatus === 'paid'
                "
              >
                <div v-if="$isSeller">ที่ต้องส่ง</div>
                <div v-if="$isBuyer">รอส่งสินค้า</div>
              </div>

              <div class="send" v-if="order.shipStatus === 'deliver'">
                รับแล้ว
              </div>
              <div class="send" v-if="order.shipStatus === 'ship'">ส่งแล้ว</div>
            </div>
            <div v-if="order.status === 'inprogress'">
              <div class="wait" v-if="order.paymentStatus === 'pending'">
                รอชำระ
              </div>
              <div class="send" v-if="order.paymentStatus === 'paid'">
                ชำระแล้ว
              </div>
            </div> -->
          </div>
          <div>
            <div
              @click.stop="$router.push('/order/' + order.id)"
              class="editBtn"
            >
              แก้ไขออเดอร์
            </div>
          </div>
        </div>
      </div>
    </div>
    <scroll-loader
      :loader-method="!loading ? () => getOrders() : () => {}"
      :loader-disable="endList || error"
      :loader-size="0"
      class="reset"
    >
      <OrderListLoader :error="error" :nodata="nodata" :fetching="loading" />
    </scroll-loader>
    <DefaultDisplayStage :error="error" :nodata="nodata" />
    <div v-if="!error && !nodata" style="height: 100px"></div>
  </div>
</template>

<script>
import OrderListLoader from "@/components/loader/OrderListLoader";
import GetOrders from "@/hooks/getOrders";
import mapOrderStatus from "@/utils/mapOrderStatus";
import DefaultDisplayStage from "@/components/DefaultDisplayStage";
import Filters from "@/views/components/Filters";
import { statusKey } from "@/utils/mapOrderStatus";
import getTextKey from "@/utils/getTextKey";

export default {
  props: {
    filters: Object,
    filtersKey: { type: Array, default: () => [] },
  },
  components: { OrderListLoader, DefaultDisplayStage, Filters },
  data() {
    return {
      orders: [],
      error: false,
      loading: false,
      page: 1,
      endList: false,
      outputFilters: this.filters,
      allfiltersOrder: [],
    };
  },
  created() {
    // prepare all config filters of order
    this.allfiltersOrder = [
      {
        key: "payment_pending",
        meta: { paymentStatus: "pending" },
      },
      {
        key: "payment_inform",
        meta: { paymentStatus: "inform" },
      },
      {
        key: "payment_paid",
        meta: { paymentStatus: "paid" },
      },
      {
        key: "ship_pending",
        meta: { shipStatus: "pending" },
      },
      {
        key: "ship_deliver",
        meta: { shipStatus: "deliver" },
      },
      {
        key: "cancel",
        meta: { status: "cancel" },
      },
      {
        key: "complete",
        meta: { status: "complete" },
      },
    ];

    this.allfiltersOrder.forEach((e) => {
      e.name = this.getStatusName(e.key);
    });
  },
  watch: {
    filters: {
      handler() {
        this.outputFilters = this.filters;
      },
      deep: true,
    },
    outputFilters: {
      handler() {
        this.resetOrders();
      },
      deep: true,
    },
    loading(v) {
      this.$emit("loading", v);
    },
  },
  computed: {
    nodata() {
      return (
        this.endList === true && this.orders.length === 0 && this.page == 1
      );
    },
    outputFiltersList() {
      return this.allfiltersOrder.filter((e) =>
        this.filtersKey.includes(e.key)
      );
    },
  },
  methods: {
    getTextKey: getTextKey,
    mapOrderStatus: mapOrderStatus,
    async getOrders() {
      this.loading = true;
      const { body, error } = await GetOrders({
        type: window.$permission.type,
        page: this.page,
        limit: 5,
        ...this.outputFilters,
      });

      this.loading = false;

      if (error) {
        this.error = true;
        return;
      }

      this.orders.push(...body.data);

      if (this.page >= body.lastPage) {
        this.endList = true;
      } else {
        this.page++;
      }
    },
    getStatusName(key) {
      return statusKey[key][this.$shopType]?.n;
    },
    updatefilters(metaJoin) {
      this.outputFilters = {
        orderStatus: this.filters.orderStatus,
        ...metaJoin,
      };
    },
    resetOrders() {
      this.page = 1;
      this.endList = false;
      this.orders = [];
      this.getOrders();
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>
<style lang="scss" scoped>
@import "./styles/tags.scss";

.shopName {
  &.sub-agent {
    color: #93c47d;
  }
  &.sub-direct {
    color: #93c47d;
  }
  &.agent {
    color: #38761d;
  }
  &.retail {
    color: #4a86e8;
  }
}

.orderList {
  overflow-y: auto;
  padding: 80px 0 0 0;
}

.card_wrap {
  color: rgb(88, 88, 88);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  position: relative;
  cursor: pointer;
  width: 100%;
  max-width: 450px;
  margin: 15px 10px 15px 10px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  // border: 1px solid #ddd;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
  // &:active::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   pointer-events: none;
  //   background: rgba(255, 255, 255, 0.301);
  // }
  /* height: 280px; */
  span {
    font-weight: bold;
  }

  .sideFlex {
    display: flex;
    justify-content: space-between;
    .left {
      padding-right: 5px;
    }
  }

  .group {
    b {
      color: rgb(95, 95, 95);
      font-size: 13px;
    }
    margin: 5px 0;
  }

  .wrapFirst,
  .sumPriceWrap {
    padding: 5px 0 0 0;
    display: flex;
    justify-content: space-between;
  }
  .line {
    margin: 0 auto;
    padding: 3px 0;
    border-bottom: 1px solid rgb(226, 226, 226);
  }
  // &:last-child {
  //   margin-bottom: 40px;
  // }

  .bottomSection {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
  }

  .editBtn {
    font-size: 14px;
    border-radius: 6px;
    padding: 3px 9px;
    font-weight: bold;
    border: 2px solid rgb(218, 218, 218);
    color: rgb(145, 145, 145);
    &:active {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}

.summaryPrice {
  color: var(--primary-color);
}
</style>
