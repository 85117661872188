<template>
  <div class="deliveryPhotoSection">
    <div class="head">
      <div class="title">
        <!-- <img src="@/assets/img/truck.svg" width="20px" alt="" /> -->
        <div class="mask-image"></div>
        หลักฐานการส่งสินค้า
      </div>
    </div>
    <div class="box">
      <div class="group">
        <b>ผู้ขาย</b>
        <UploadDeliveryPhotos
          ref="shipPhotosUpload"
          :name="'shipPhotos'"
          :orderId="order.id"
          uploadType="ship"
          :canUpload="this.$isSeller"
        />
      </div>
      <hr />
      <div class="group">
        <b>ผู้ซื้อ</b>
        <UploadDeliveryPhotos
          ref="receivePhotosUpload"
          :name="'receivePhotos'"
          :orderId="order.id"
          uploadType="receive"
          :canUpload="this.$isBuyer"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UploadDeliveryPhotos from "./UploadDeliveryPhotos";

export default {
  props: { order: Object },
  components: { UploadDeliveryPhotos },
  watch: {
    order: {
      deep: true,
      handler() {
        this.$refs.shipPhotosUpload.selectedImgs = this.order.shipPhoto;
        this.$refs.receivePhotosUpload.selectedImgs = this.order.receivePhoto;
      },
    },
  },
  mounted() {
    this.$refs.shipPhotosUpload.selectedImgs = this.order.shipPhoto;
    this.$refs.receivePhotosUpload.selectedImgs = this.order.receivePhoto;
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/orderDetail.scss";

.deliveryPhotoSection {
  .mask-image {
    mask-image: url("~@/assets/img/truck.svg");
  }
  .group {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .item {
    align-items: center;
    .js-smartphoto {
      max-width: 25%;
      margin-right: 10px;
      border-radius: 8px;
      overflow: hidden;
    }
    img {
      object-fit: scale-down; /* Do not scale the image */
      object-position: center;
      aspect-ratio: 1/1;
      width: 100%;
    }
    display: flex;
  }
  hr {
    border-top: 1px solid #ebebeb;
  }
}
</style>