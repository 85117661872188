<template>
  <div class="root">
    <div />
    <div class="container">
      <div :key="index" class="row" v-for="(item, index) in history_data">
        <div class="dot-container">
          <div class="dot-base">
            <div v-if="index == 0" class="dot active" />
            <div v-else class="dot" />
          </div>
        </div>
        <div class="detail-container">
          <div class="event">
            <strong v-if="item.status === 'checkout'">สร้างออเดอร์ใหม่</strong>
            <strong v-else-if="item.status === 'pending'">ยกเลิกอนุมัติ</strong>
            <strong v-else-if="item.status === 'edit'">แก้ไขออเดอร์</strong>
            <strong v-else-if="item.status === 'approve'">ยืนยันออเดอร์</strong>
            <strong v-else-if="item.status === 'cancel'">ยกเลิกออเดอร์</strong>
            <strong v-else-if="item.status === 'cancel payment'"
              >ยกเลิกชำระเงิน</strong
            >
            <strong v-else-if="item.status === 'inform'">แจ้งชำระเงิน</strong>
            <strong v-else-if="item.status === 'paid'">ยืนยันชำระเงิน</strong>
            <strong v-else-if="item.status === 'ship'">ส่งสินค้า</strong>
            <strong v-else-if="item.status === 'receive'">รับสินค้า</strong>
            <strong v-else-if="item.status === 'done'">เสร็จสิ้น</strong>
          </div>
          <div class="detail">
            <div
              v-if="item.note != null || item.note == ''"
              class="note-container"
            >
              หมายเหตุ: {{ item.note }}
            </div>

            <p>{{ item.shop_name }} / {{ item.name }}</p>

            <p>{{ generateThaiDate(item.createdAt) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  computed: {
    history_data() {
      return this.data.map((obj) => ({
        ...obj,
        note: obj.note,
        name: obj.user?.name,
        shop_name: obj.shop?.name,
      }));
    },
  },
  methods: {
    generateThaiDate(n) {
      const monthNamesThai = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ];

      const date = new Date(n);
      let month = date.getMonth();
      const hour = date.getHours();
      const min = date.getMinutes();
      let result =
        date.getDate() +
        " " +
        monthNamesThai[month] +
        " " +
        (date.getFullYear() + 543);
      if (hour > 9) {
        if (min > 9) {
          result += " " + hour + ":" + min + " น.";
        } else {
          result += " " + hour + ":0" + min + " น.";
        }
      } else {
        if (min > 9) {
          result += " 0" + hour + ":" + min + " น.";
        } else {
          result += " 0" + hour + ":0" + min + " น.";
        }
      }
      return result;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.root {
  display: flex;
  &::before {
    content: "";
    position: relative;
    width: 2px;
    left: 13px;
    width: 2px;
    background: rgba(0, 0, 0, 0.12);
  }
}
.container {
  padding-top: 8px;
  flex: 1;
}
.row {
  display: flex;
  padding-bottom: 16px;
}
.dot-container {
  display: flex;
  justify-content: center;
  margin-right: 16px;
  z-index: 0;
}
.dot-base {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.dot {
  height: 18px;
  margin: 3px;
  width: 18px;
  border-radius: 50%;
  background-color: #9e9e9e;

  &.active {
    background-color: var(--primary-color);
  }
}
.detail-container {
  flex: 1;
}
.event {
  font-size: 16px;
}
.note-container {
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.detail {
  padding-left: 8px;
}
</style>