<template>
  <transition name="slidefade">
    <div class="modal">
      <div class="wrapContent">
        <div class="head">
          <!-- <img
            class="backBtn"
            @click="$router.back()"
            src="@/assets/img/left-arrow.svg"
            alt=""
          /> -->
          <div @click="$router.back()" class="backBtn mask-image"></div>
          <input class="queryInput" type="text" v-model="queryInput" />
          <div :class="[{ disable: loading }, 'searchBtn']" @click="search()">
            ค้นหา
          </div>
        </div>
        <div class="result">
          <OrderList :filters="filters" @loading="loading = $event" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import OrderList from "./OrderList";

export default {
  name: "SearchOrderPage",
  components: { OrderList },
  data() {
    return {
      filters: {
        // orderStatus: ["inProgress"],
        query: "",
        // status: ["approve"],
        // shipStatus: ["pending", "ship"],
      },
      queryInput: "",
      loading: false,
    };
  },
  methods: {
    search() {
      this.filters.query = this.queryInput;
    },
    stateUpdate(val) {
      console.log("state update", val);
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(255, 255, 255); /* Fallback color */
  box-sizing: border-box;
}

.wrapContent {
  width: 100%;
  height: 100%;
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  width: 100%;
  height: 100%;
}

.backBtn {
  &.mask-image {
    width: 30px;
    height: 30px;
    mask-image: url("~@/assets/img/left-arrow.svg");
  }
}

.queryInput {
  padding: 5px 10px;
}

input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  margin: 0 10px;
  border: 1px solid #b9b9b9;
  background: #ffffffbd;
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 10px;
  padding-top: 15px;
  flex-wrap: nowrap;
  overflow: hidden;
  z-index: 10;
}

.searchBtn {
  margin: 0 auto;
  width: 80px;
  display: inline-flex;
  border-radius: 10px;
  justify-content: center;
  background: #ffffffc7;
  align-items: center;
  padding: 2px;
  font-size: 14px;
  /* box-shadow: 0 0px 2px 0 rgb(0 0 0 / 50%); */
  border: 1px solid var(--primary-color);
  height: 40px;
  color: var(--primary-color);
  // font-weight: bold;
  &.disable {
    pointer-events: none;
    background: #69696923;
    box-shadow: none;
    color: #a3a3a3;
  }
  &:active {
    background: #dfdfdf81;
  }
}

.result {
  overflow-y: auto;
  height: 100%;
  padding: 0px 10px 0px 10px;
}
</style>