<template>
  <div class="orderDetail" v-if="order.id && orderLists">
    <NavbarBack title="แก้ไขออเดอร์" />
    <div class="addProductBtn" @click="showSearchProduct = true">
      <!-- <img src="@/assets/img/plus-in-cgreen-circle.svg" /> -->
      <div class="mask-image"></div>
      เพิ่มสินค้า
    </div>
    <div class="productList" ref="productList">
      <div
        class="item"
        v-for="item in orderLists"
        :key="item.id"
        :id="'item_' + item.id"
      >
        <div class="productDetail">
          <div class="wrapImg">
            <img
              v-if="
                item.product.productPhotos &&
                item.product.productPhotos.length > 0
              "
              :src="item.product.productPhotos[0].url"
              width="70px"
            />
          </div>
          <div class="delete" @click.stop="remove(item.id)">
            <img src="@/assets/img/trash.svg" />
          </div>
          <div class="wrapRight">
            {{ item.premium ? "[ของแถม]" : "" }} {{ item.product.name }}
            <div class="priceWrap">
              <span class="price">
                <span v-if="calStep(item.product)"
                  >{{ calStep(item.product).min.toLocaleString() }} ~
                  {{ calStep(item.product).max.toLocaleString() }}</span
                >
                <span v-else>{{
                  item.product.salePrice.toLocaleString()
                }}</span>
              </span>
              <span class="unitPrice">บาท</span>
              <span class="fromPrice"
                >{{ item.product.price.toLocaleString() }}
                <span class="unitPrice">บาท</span></span
              >
            </div>
          </div>
        </div>
        <div class="calDetail">
          <div class="amout">
            จำนวน
            <input
              class="amountInput"
              type="number"
              v-model="item.qty"
              @change="updateQty(item)"
            />
            {{ item.product.unit }}
          </div>
          <div class="sumarySection">
            <div class="group">
              <label>ราคาต่อหน่วย</label>
              <div>{{ item.unitPrice.toLocaleString() }}</div>
            </div>
            <div class="group">
              <label>รวม</label>
              <div>{{ item.total.toLocaleString() }}</div>
            </div>
            <div class="group">
              <label>ส่วนลดเพิ่ม</label>
              <div>
                <input
                  class="discountInput"
                  type="number"
                  v-model="item.discount"
                  @change="updateItemDiscount(item)"
                />
              </div>
            </div>
            <div class="group">
              <label><b>ราคาหลังลด</b></label>
              <div>{{ item.net.toLocaleString() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actionSection">
      <div class="sumPriceWrap">
        <div class="group">
          <label>รวม</label>
          <div>{{ order.total.toPrice() }}</div>
        </div>
        <div class="group">
          <label>ส่วนลดโปรโมชั่น</label>
          <div>{{ order.promotion.toPrice() }}</div>
        </div>
        <div class="group">
          <label>ส่วนลดท้ายบิล</label>
          <div>
            <input
              class="discountInput"
              type="number"
              v-model="order.discount"
              @change="updateOrderDiscount()"
            />
          </div>
        </div>
        <div class="group">
          <label><b>รวมทั้งหมด</b></label>
          <div>
            <span class="summaryPrice"
              ><b>{{ order.net.toPrice() }}</b></span
            >
          </div>
        </div>
      </div>
      <div class="comfirmBtn" @click="updateOrder()">บันทึกข้อมูล</div>
    </div>
    <SearchProduct
      :show="showSearchProduct"
      @close="showSearchProduct = false"
      @result="addProduct"
      :isAddOrder="true"
    />
  </div>
</template>

<script>
import NavbarBack from "@/components/NavbarBack";
import SearchProduct from "./components/SearchProduct";
import { GET, PUT } from "@/utils/http";
import { calStep, calUnitPrice } from "@/utils/product";

export default {
  props: { orderId: String },
  components: { NavbarBack, SearchProduct },
  data() {
    return {
      showSearchProduct: false,
      commentConfirm: "",
      orderLists: [],
      order: {},

      updateBody: {
        discount: 200,
        updates: [],
        deletes: [],
      },
    };
  },
  methods: {
    calStep,
    calUnitPrice,
    async addProduct(product) {
      this.showSearchProduct = false;

      // const indexExist = this.orderLists.findIndex(
      //   (e) => e.product.id === product.id
      // );
      // if (indexExist < 0) {
      const newItemId = -Math.abs(+new Date());
      const newItem = {
        id: newItemId,
        qty: 1,
        product: product,
        discount: 0,
      };
      const newUnitPrice = await this.getUnitPrice(newItem);
      const total = 1 * newUnitPrice;
      newItem.net = total;
      newItem.total = total;
      newItem.unitPrice = total;

      this.orderLists.push(newItem);
      setTimeout(() => {
        this.$nextTick(() => {
          document
            .getElementById("item_" + newItemId)
            .scrollIntoView({ behavior: "smooth" });
        }, 100);
      });
      // } else {
      //   this.orderLists[indexExist].qty =
      //     parseInt(this.orderLists[indexExist].qty) + 1;

      //   const unitPrice = await this.getUnitPrice(this.orderLists[indexExist]);
      //   const newTotal = this.orderLists[indexExist].qty * unitPrice;
      //   this.orderLists[indexExist].unitPrice = unitPrice;
      //   this.orderLists[indexExist].total = newTotal;
      // }
      this.summary();
    },
    summary() {
      let orderTotal = 0;
      this.orderLists.forEach((item) => {
        item.net = item.total - parseFloat(item.discount);
        orderTotal += item.net;
      });
      this.order.total = orderTotal;
      this.order.net = this.order.total - this.order.discount;
    },

    async getOrder() {
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}`
      );
      if (status !== 200) {
        throw "order index: not ok is status " + status;
      }
      this.updateBody.discount = body.discount;
      this.order = body;
    },
    async getOrderLists() {
      const { status, body } = await GET(
        `/api/shops/${this.$myShop.id}/orders/${this.orderId}/orderlists?query=&order=unitPrice:desc`
      );
      if (status !== 200) {
        throw "order: not ok";
      }
      this.orderLists = body;
    },
    async remove(orderListId) {
      if (!(await window.$alert("ต้องการลบหรือไม่?", "confirm"))) {
        return;
      }

      if (parseInt(orderListId) > 0) {
        this.updateBody.deletes.push(orderListId);
      }
      this.orderLists = this.orderLists.filter((e) => e.id !== orderListId);
      console.log(this.updateBody);
      this.summary();
    },
    async updateOrder() {
      if (!(await window.$alert("ยืนยันการอัปเดต?", "confirm"))) {
        return;
      }

      // prepare data
      this.updateBody.updates = this.orderLists.map((e) => {
        console.log(e);
        return {
          ...(parseInt(e.id) > 0 && { orderListId: e.id }),
          productId: parseInt(e.product.id),
          qty: parseInt(e.qty),
          discount: parseFloat(e.discount),
        };
      });

      this.updateBody.discount = parseFloat(this.order.discount);

      console.log(this.updateBody);

      // put to update
      try {
        window.loading(true);
        const { status } = await PUT(
          `/api/shops/${this.$myShop.id}/orders/${this.orderId}`,
          this.updateBody
        );
        window.loading(false);

        if (status !== 200) {
          throw "update order : not ok is status " + status;
        }

        await window.$alert("อัปเดตออเดอร์แล้ว");
        this.$router.replace("/order/" + this.orderId);
      } catch (err) {
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      }
    },
    async getUnitPrice(item) {
      let query = "";
      if (item.id > 0) {
        query = `orderListId=${item.id}`;
      }

      try {
        window.loading(true);
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/products/${item.product.id}/qty/${item.qty}/price?${query}`
        );
        window.loading(false);
        if (status !== 200) {
          throw "get price: not ok";
        }
        return body.price;
      } catch (err) {
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      }
    },
    async updateQty(item) {
      const itemQty = (item.qty = parseInt(item.qty));
      if (!itemQty || itemQty < 1) {
        item.qty = 1;
      }
      const unitPrice = await this.getUnitPrice(item);
      item.unitPrice = unitPrice;
      item.total = item.qty * unitPrice;
      this.summary();
    },
    async updateOrderDiscount() {
      const orderDiscount = (this.order.discount = parseFloat(
        this.order.discount
      ));
      if (!orderDiscount || orderDiscount < 0) {
        this.order.discount = 0;
      }
      this.summary();
    },
    async updateItemDiscount(item) {
      const itemDiscount = (item.discount = parseFloat(item.discount));
      if (!itemDiscount || itemDiscount < 0) {
        item.discount = 0;
      }
      this.summary();
    },
    async init() {
      window.loading(true);
      try {
        await Promise.all([this.getOrderLists(), this.getOrder()]);
      } catch (err) {
        await window.$alert("พบข้อผิดพลาดการขอข้อมูล");
        this.$router.back();
      } finally {
        window.loading(false);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.amountInput,
.discountInput {
  text-align: right;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 6px;
}

.discountInput {
  width: 85px;
}

.amountInput {
  width: 60px;
}

.addProductBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 13px;
  color: var(--primary-color);
  font-weight: bold;
  background: rgb(238, 238, 238);
  position: absolute;
  padding: 6px 10px;
  top: 7px;
  right: 10px;
  z-index: 10;
  > img {
    margin-right: 4px;
  }
  .mask-image {
    background-color: var(--primary-color);
    height: 20px;
    width: 20px;
    margin-right: 4px;
    mask-image: url(~@/assets/img/plus-in-cgreen-circle.svg);
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
  }
}
.confirmTitle {
  font-size: 17px;
}

.commentTitle {
  line-height: 25px;
  text-align: left;
  font-size: 14px;
}
#commentConfirm {
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgb(212, 212, 212);
}

.orderNumberSection {
  font-size: 14px;
}

.actionSection {
  position: fixed;
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 12%);
  bottom: 0;
  width: 100%;
  padding: 0 10px;
  background: #fff;
  .comfirmBtn,
  .cancelBtn {
    text-align: center;
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    padding: 15px;
    font-weight: bold;
    display: block;
    margin: 12px auto;
  }

  .comfirmBtn {
    background: var(--primary-color);
    color: #fff;
  }

  .cancelBtn {
    background: #fff;
    color: rgb(117, 117, 117);
    border: 2px solid rgb(218, 218, 218);
  }
}

.orderDetail {
  padding: 40px 0 20px 0;
}

.line {
  margin: 0 auto;
  padding: 3px 0;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.productList {
  padding: 10px;
  > .item {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 10px auto;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    border-radius: 15px;
    border: 1px solid #ddd;

    &:last-child {
      margin-bottom: 180px !important;
    }
    .wrapImg {
      margin-right: 7px;
    }

    .delete {
      position: absolute;
      width: 20px;
      right: 10px;
      top: 10px;
      img {
        width: 100%;
      }
    }

    .wrapRight {
      padding: 10px;
      padding-right: 35px;
    }
    .priceWrap {
      .fromPrice {
        font-size: 12px;
        margin-left: 6px;
        color: #a5a5a5;
        text-decoration: line-through;
      }

      .price {
        font-size: 13px;
        padding-right: 4px;
        color: var(--primary-color);
      }
    }
    .head {
      display: flex;
      justify-content: space-between;
    }
    .productDetail {
      display: flex;
    }
    .calDetail {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      .amout {
        white-space: nowrap;
      }
      display: flex;
      .sumarySection {
        margin-left: auto;
        .group {
          margin: 4px 0;
          display: flex;
          justify-content: space-between;
          label {
            margin-right: 10px;
          }
        }
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      .group {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.sumPriceWrap {
  .group {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    label {
      margin-right: 10px;
    }
  }
  .summaryPrice {
    color: var(--primary-color);
    font-size: 15px;
  }
}

/* The Modal (background) */
.alertConfirm {
  #alertBody {
    color: rgb(122, 122, 122);
    margin: 0 0 5px 0;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-size: unset !important;
  }

  #okBtn {
    background: var(--primary-color);
    color: #fff;
    // width: 50px;
    // height: 25px;
  }

  #cancelBtn {
    background: #fff;
    color: rgb(117, 117, 117);
    border: 2px solid rgb(218, 218, 218);
  }

  .wrapAction {
    font-size: 14px;
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }
  .action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    &:active {
      background: rgba(226, 226, 226, 0.507);
    }
  }
}
</style>
