<template>
  <div class="orderConfirm h100">
    <OrderList :filters="filters" />
  </div>
</template>
<script>
import OrderList from "./OrderList";

export default {
  components: { OrderList },
  data() {
    return {
      filters: {
        orderStatus: ["pending"],
      },
    };
  },
};
</script>