
import { GET } from "@/utils/http";

export default async function ({ type = "", page = 1, limit = 1, paymentStatus = [], shipStatus = [], status = [], orderStatus = [], query = "" }) {
    let response = ({ status: null, body: null, error: null })
    try {
        let url = ""
        if (type === "buyer") {
            url = `/api/shops/${window.$permission.myShop.id}/buyer/orders?query=${query}&skip=0&page=${page}&limit=${limit}&order=createdAt:desc&orderStatus=${orderStatus.join(",")}&paymentStatus=${paymentStatus.join(",")}&shipStatus=${shipStatus.join(",")}&status=${status.join(",")}`
        }
        if (type === "seller") {
            url = `/api/shops/${window.$permission.myShop.id}/seller/orders?query=${query}&skip=0&page=${page}&limit=${limit}&order=createdAt:desc&orderStatus=${orderStatus.join(",")}&paymentStatus=${paymentStatus.join(",")}&shipStatus=${shipStatus.join(",")}&status=${status.join(",")}`
        }

        response = await GET(url);
        if (response.status !== 200) {
            response.error = "NOT_OK"
        }
        return response
    } catch (err) {
        response.error = err
        return response
    }
}