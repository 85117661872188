<template>
  <div class="orderProgress h100">
    <OrderList :filters="filters" :filtersKey="filtersKey" />
  </div>
</template>
<script>
import OrderList from "./OrderList";

export default {
  components: { OrderList },
  data() {
    return {
      filters: {
        orderStatus: ["inProgress"],
      },
      filtersKey: [
        "payment_pending",
        "payment_inform",
        "payment_paid",
        "ship_pending",
        "ship_deliver",
      ],
    };
  },
};
</script>