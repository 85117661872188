<template>
  <div class="paymentsSection">
    <div class="head">
      <div class="title">
        <!-- <img src="@/assets/img/credit-card.svg" width="20px" alt="" /> -->
        <div class="mask-image"></div>
        การชำระเงิน
      </div>
    </div>
    <div class="box">
      <div v-for="(payment, index) in payments" :key="index" class="payment">
        <a
          v-if="payment.transferSlip != ''"
          :href="payment.transferSlip"
          class="js-smartphoto"
          data-id="bear"
          data-group="animal"
        >
          <img :src="payment.transferSlip" />
        </a>
        <div>
          <div>
            <b>ช่องทางชำระ</b>
            {{ payment.shopPaymentMethod.paymentMethod.name }}
          </div>
          <div>
            <div v-if="payment.shopPaymentMethodDetail">
              {{
                payment.shopPaymentMethodDetail.bank &&
                payment.shopPaymentMethodDetail.bank.name
              }}
              {{ payment.shopPaymentMethodDetail.branchName }} <br />
              <b>ชื่อบัญชี</b>
              {{ payment.shopPaymentMethodDetail.accountName }} <br />
              <b>เลขที่บัญชี</b>
              {{ payment.shopPaymentMethodDetail.accountNo }}
            </div>
          </div>

          <!-- cheque -->
          <div v-if="payment.bank">{{ payment.bank.name }}</div>
          <div v-if="payment.branchName">{{ payment.branchName }}</div>
          <div v-if="payment.chequeNo">
            <b>เลขที่</b> {{ payment.chequeNo }}
          </div>

          <div v-if="payment.amount">
            <b>จำนวน</b> {{ payment.amount.toPrice() }} บาท
          </div>
          <div v-if="payment.transferTime">
            <b>วันที่ชำระ</b> {{ $formatDate(payment.transferTime) }}
          </div>
          <div>
            <b>สถานะ: </b>
            <span v-if="payment.approve == 'pending'" class="pending"
              >รอการตรวจสอบ</span
            >
            <span v-if="payment.approve == 'approve'" class="approve"
              >ตรวจสอบแล้ว</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { payments: Array },
};
</script>
<style lang="scss" scoped>
@import "../styles/orderDetail.scss";

.paymentsSection {
  .mask-image {
    mask-image: url("~@/assets/img/credit-card.svg");
  }
  .payment {
    align-items: center;
    .js-smartphoto {
      max-width: 25%;
      margin-right: 10px;
      border-radius: 8px;
      overflow: hidden;
    }
    img {
      object-fit: scale-down; /* Do not scale the image */
      object-position: center;
      aspect-ratio: 1/1;
      width: 100%;
    }
    display: flex;
    .pending {
      font-weight: bold;
      color: rgb(185, 99, 0);
    }
    .approve {
      font-weight: bold;
      color: rgb(0, 126, 0);
    }
  }
}
</style>