<template>
  <div class="orderListSection">
    <div class="head">
      <div class="title"><ProductIcon /> รายการสินค้า</div>

      <div
        v-if="$isSeller && order.status === 'pending'"
        class="editProductBtn"
        @click="$router.push(`/order/${order.id}/edit`)"
      >
        <img src="@/assets/img/edit.svg" width="15px" />
        <p>แก้ไขสินค้า</p>
      </div>
    </div>
    <div class="productListBox">
      <div class="item" v-for="(item, index) in orderlist" :key="index">
        <OrderListItemDetail
          :item="item"
          :showDelivery="order.status !== 'pending'"
        />
        <div v-if="!item.premium" class="calDetail">
          <div></div>
          <div class="sumarySection">
            <div class="group">
              <label>รวม</label>
              <div>{{ item.total.toLocaleString() }}</div>
            </div>
            <div class="group">
              <label>ส่วนลดเพิ่ม</label>
              <div>{{ item.discount.toLocaleString() }}</div>
            </div>
            <div class="group">
              <label><b>ราคาหลังลด</b></label>
              <div>{{ item.net.toLocaleString() }}</div>
            </div>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="sumPriceWrap">
        <div class="group">
          <label><b>ราคารวม</b></label>
          <div>{{ order.total.toPrice() }}</div>
        </div>
        <div class="group">
          <label><b>ส่วนลดโปรโมชั่น</b></label>
          <div>{{ order.promotion.toPrice() }}</div>
        </div>
        <div class="group">
          <label><b>ส่วนลดคูปอง</b></label>
          <div>{{ order.couponDiscount.toPrice() }}</div>
        </div>
        <div class="group">
          <label><b>ส่วนลดท้ายบิล</b></label>
          <div>{{ order.discount.toPrice() }}</div>
        </div>
        <div class="group">
          <label><b>รวมทั้งหมด</b></label>
          <div>
            <span class="summaryPrice"
              ><b>{{ order.net.toPrice() }}</b></span
            >
          </div>
        </div>
        <div v-if="order.status === 'complete'" class="group">
          <label><b>คะแนนที่ได้รับ</b></label>
          <div>
            <span class="summaryPrice"
              ><b>{{ order.point.toPrice() }}</b></span
            >
          </div>
        </div>
        <div v-else class="group">
          <label><b>คะแนนจะที่ได้รับ</b></label>
          <div>
            <span class="summaryPrice"
              ><b>{{ order.expectPoint.toPrice() }}</b></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductIcon from "@/components/menuIcon/ProductIcon";
import OrderListItemDetail from "./OrderListItemDetail.vue";

export default {
  props: { order: Object, orderlist: Array },
  components: {
    ProductIcon,
    OrderListItemDetail,
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/orderDetail.scss";

.editProductBtn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: rgb(131, 131, 131);
  font-weight: bold;
  border: 2px solid rgb(233, 233, 233);
  padding: 6px 6px;
  font-size: 14px;
  border-radius: 8px;
  p {
    margin-left: 3px;
  }
}

.productListBox {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ddd;
  .head {
    display: flex;
    justify-content: space-between;
  }
  .item {
    font-size: 14px;
    .line {
      width: 100%;
      height: 1px;
      background: rgb(230, 230, 230);
      margin: 15px auto;
      box-shadow: 0 0 2px rgba(80, 80, 80, 0.1);
    }
  }
  .calDetail {
    display: flex;
    justify-content: space-between;
    .amout {
      white-space: nowrap;
    }
    display: flex;
    .sumarySection {
      margin-left: 10px;
      .group {
        margin: 0 0 4px 0;
        display: flex;
        justify-content: space-between;
        label {
          margin-right: 10px;
        }
      }
    }
  }

  .sumPriceWrap {
    .group {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      label {
        margin-right: 10px;
      }
    }
    .summaryPrice {
      color: var(--default-color);
      font-size: 15px;
    }
  }
}
</style>